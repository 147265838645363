<template lang="pug">
  div
    h1 User Data Deletion
    p Please email stinkytofureviews@gmail.com
</template>

<script>
// @ is an alias to /src


export default {
  name: 'UserDataDeletion',
  components: {
   
  },
  data(){
    return{
      loading:true,
    }
  },
  metaInfo: {
    title: '臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{

  },
  async created(){

      
  }
}
</script>
<style lang="scss" scoped>
  

</style>
